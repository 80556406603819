import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit.svg';
import garbageIcon from '../../assets/garbage.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';
class Screen extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {      
            articlesList: true,
            articleForm: false,
            total: 20,
            modules: [],
            items: [
    
            ]
        }
    }

    componentDidMount() {
       this._onLoad();
    }

    componentDidUpdate(prevProps, prevState){
        if (this.props[0].location.search != prevProps[0].location.search){
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();
        console.log(this.state)

        return (
            <div>
                <div className="section-title title-options">
                    <h1>User levels</h1>
                    <div className="title-options-group">
                        <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Save</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Print</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm:{} }, () => { this._onLoad();this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Delete user level <strong>{this.state.deleteModal ? this.state.deleteModal.name:''}</strong> ?
                </DeleteModal>
                    <Row>
                        <Col lg="6">                            
                            <FormBuilder
                             registerSubmitAction={(submit) => this.submit = submit}
                                classNameForm={"add-form-container"}
                                // headerForm={"Artikal".translate(this.props.lang)}
                                addButtonText={'Add'.translate(this.props.lang)}
                                    onSubmit={(dat) => {
                                        
                                        let data = {...dat};

                                        data.modules = [];
                                        if (data.module) {
                                            for (var key in data.module) {
                                                if (data.module[key]) {
                                                    data.modules.push(key);
                                                }
                                            }
                                           delete data.module; 
                                        }
    
                                        if (this.state.form && this.state.form._id) {
                                           
                                            this._api('api4', { data: data }, () => {
                                                this.setState({ form: undefined }, () => { this._onLoad(); })
                                            });
                                        } else {
    
                                            this._api('api2', { data: data }, () => {
                                                this.setState({ clientForm: {} }, () => { this._onLoad() })
                                            });
                                        }
                                    }}
                                    initialValues={this.state.form ? this.state.form : null}
                                    fields={[
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'User level'.translate(this.props.lang),
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'name',
                                                                            next: 'level',
                                                                            label: 'Name',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Required!'),
                                                                            ]
                                                                        },
                        
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'level',
                                                                            label: 'Level',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Required!'),
                                                                                number('Must be number'),
                                                                                async (value) => {
                                                                                    try {
                                                                                        if (value) {
                                                                                            let res = await this._apiAsync('validate', { value: value, id: this.state.form ? this.state.form._id : null });
                                                                                            if (res.status == 400) {
                                                                                                return 'Level is already in use'
                                                                                            }
                                                                                            return undefined;
                                                                                        }
    
                                                                                    } catch (e) {
                                                                                        return undefined;
                                                                                    }
                                                                                },

                                                                            ]
                                                                        },
                                                                    ]
                                                                },

                                                                ...this.state.modules.map((item) => {
                                                                    return {
    
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'switch',
                                                                                name: 'module.' + item._id,
                                                                                label: item.name,
    
                                                                            }
                                                                        ]
    
    
    
                                                                    }
                                                                })
                                                                
                                                            ]
                                                        },

                                                    ]
                                                }
                                            ]
                                        },
                                        
                                        
                                    ]}
                            ></FormBuilder>
                        </Col>
                        <Col lg="6">
                            <div className="table-modifed ml-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    header={'List'}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'name', label: 'NAME'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'level', label: 'LEVEL'.translate(this.props.lang), multilang: false, allowSort: true },
                                       
                                    ]}
                                    items={this.state.items}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} className="delete-icon" />,
                                                onClick: (item) => {

                                                    if (item.modules) {
                                                        for (let i = 0; i < item.modules.length; i++)
                                                            item['module.' + item.modules[i]] = true;
                                                    }



                                                     this.setState({ form: null }, () => this.setState({form: item}))

                                                }
                                                //onClick: (item) => console.log(item)
                                            },
                                            {
                                                component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                             </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(Screen);
