
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Dropzone from 'react-dropzone';
import GridLayout from 'react-grid-layout';
import env from "react-dotenv";
import imageIcon from '../../assets/image.svg';
import Isvg from 'react-inlinesvg';
import removeImageIcon from '../../assets/remove-image.svg';
import axios from 'axios';


class MediaGallery extends Component {
    constructor(props) {
        super(props);
        this.dropzone = React.createRef()
        this.removeImage = this.removeImage.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.state = {
            files: [],
            imagesLayout: [],
            _uploading: [],
        };
    }


    componentDidMount() {
        let files = [];
        let imagesLayout = [];

        if (this.props.value) {
            for (let i = 0; i < this.props.value.length; i++) {
                files.push(this.props.value[i]);
                imagesLayout.push(i);
            }
        }

        this.setState({
            files: files,
            imagesLayout: imagesLayout,
        });

    }



    componentDidUpdate(prevProps, prevState) {
        console.log(this.props.value);

        if (!prevProps.value && this.props.value && this.props.value.length) {
            let files = [];
            let imagesLayout = [];

            if (this.props.value) {
                for (let i = 0; i < this.props.value.length; i++) {
                    files.push(this.props.value[i]);
                    imagesLayout.push(i);
                }
            }

            this.setState({
                files: files,
                imagesLayout: imagesLayout,
            });

        }
    }




    removeImage(idx) {
        console.log(idx);

        let files = this.state.files.slice(0, idx).concat(this.state.files.slice(idx + 1, this.state.files.length))
        let imagesLayout = this.state.imagesLayout.slice(0, idx).concat(this.state.imagesLayout.slice(idx + 1, this.state.imagesLayout.length))
        let _uploading = this.state._uploading.slice(0, idx).concat(this.state._uploading.slice(idx + 1, this.state._uploading.length))
        this.setState({
            files: files,
            imagesLayout: imagesLayout,
            _uploading: _uploading
        });
    }


    async onDrop(imageFiles) {

        let imagesLayout = [];
        let images = [];
        let _uploading = [];

        for (let i = 0; i < imageFiles.length; i++) {
            let formData = new FormData();
            formData.append('file', imageFiles[i]);
            let name = this.state.files.length + i;




            axios.post(env.API_URL + this.props.endpoint, formData, {
                headers: {
                    Accept: 'application/json',
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                },
                onUploadProgress: (progressEvent) => {
                    let _uploading = this.state._uploading;
                    _uploading[name] = { progress: (progressEvent.loaded * 100) / progressEvent.total };

                    this.setState({
                        _uploading: _uploading,
                    })


                }
            }).then((response) => {

                let img = response.data;

                if (img.error) {
                    img = null;
                }

                this.props.onChange(img);
                this.setState({
                    _loading: null
                })

                let files = this.state.files;
                let _uploading = this.state._uploading;
                files[name] = img;
                _uploading[name] = null;

                this.setState({
                    _uploading: _uploading,
                    files: files
                }, () => {
                    this.props.onChange(this.state.files);
                })


            }).catch((error) => {
                this.props.onChange(null);
                this.setState({
                    _loading: null
                })
            })



            images.push(null);
            imagesLayout.push(i);
            _uploading.push({ progress: 0 });
        }

        this.setState({
            files: this.state.files.concat(images),
            imagesLayout: this.state.imagesLayout.concat(imagesLayout),
            _uploading: this.state._uploading.concat(_uploading)
        }, () => {
            let files = [];
            for (let i = 0; i < this.state.imagesLayout.length; i++) {
                files.push(this.state.files[this.state.imagesLayout[i]]);
            }

            this.props.onChange(files);
        });

    }



    onLayoutChange(layout) {
        //console.log(layout);
        let arr = [];
        for (let i = 0; i < layout.length; i++) {
            arr.push({ idx: layout[i].i, position: layout[i].y * 3 + layout[i].x });
        }

        arr.sort(function (a, b) { return (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0); });
        let imagesLayout = [];
        for (let i = 0; i < arr.length; i++) {
            imagesLayout.push(parseInt(arr[i].idx));
        }


        this.setState({
            imagesLayout: imagesLayout
        }, () => {
            let files = [];
            for (let i = 0; i < this.state.imagesLayout.length; i++) {
                files.push(this.state.files[this.state.imagesLayout[i]]);
            }

            this.props.onChange(files);


        });

    }


    render() {

        let images = [];

        if (this.state.files) {
            let x = 0;
            let y = 0;
            for (let i = 0; i < this.state.files.length; i++) {
                let layout = { i: i.toString(), x: x, y: y, w: 1, h: 1 };
                let item = {
                    content: (
                        <div className="image" key={i.toString()} data-grid={layout}>
                            <div className="image-wrap">
                                {this.state.files[i] ? <img src={env.API_URL + this.state.files[i].thumbnail} /> : null}
                                {
                                    this.state._uploading[i] ?
                                        <div className="progress-wrap">
                                            <div className="progress"><div style={{ width: this.state._uploading[i].progress + '%' }}></div></div>
                                            <span>{Math.round(this.state._uploading[i].progress) + '%'}</span>
                                        </div> : null
                                }
                                {!this.state._uploading[i] ?
                                    <button className={'delete'} onClick={() => this.removeImage(i)}>
                                        <Isvg src={removeImageIcon} />
                                    </button>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    )
                };
                images.push(item);
                x++;
                if (x >= 3) {
                    y++;
                    x = 0;
                }
            }



        }


        return (
            <div className={`media-gallery ${this.props.error ? 'media-gallery-error' : ''}`}>
                <div ref={(ref) => this.dropzone = ref}>
                    <Dropzone
                        onDrop={this.onDrop}
                        className='dropzone'
                        activeClassName='active-dropzone'
                        multiple={true}>

                    </Dropzone>
                    <div className="bottom-content">
                        <p><Isvg src={imageIcon} /> <span>Upload a file</span> or drag and drop</p>
                    </div>

                    <GridLayout
                        className="grid-layout"
                        onLayoutChange={this.onLayoutChange}
                        width={this.dropzone.offsetWidth}
                        margin={[10, 10]}
                        rowHeight={110}
                        compactType={'horizontal'}
                        isResizable={false}
                        //verticalCompact={true}
                        horizontalCompact={true}
                        useCSSTransforms={false}
                        cols={3}
                    >

                        {
                            images.map((image, idx) => {
                                return (
                                    image.content
                                );
                            })
                        }
                    </GridLayout>


                </div>





            </div>
        );
    }
}

export default MediaGallery;