import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import Sidebar from '../components/sidebar';


function iosDevice() {
    var userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        return true;
    }
    else {
        return false;
    }
}


export const DefaultLayout = (Wrapped) => (props) => {
    console.log(props[0].location.pathname)
    return (
        <div className={window.location.href !== `${window.location.origin}/login` && props.uData ? 'main-layout' : 'main-layout no-padding'} style={typeof window != 'undefined' && iosDevice() ? { maxWidth: `${window.innerWidth}px` } : {}}>
            {
                props[0].location.pathname !== '/login' && props.uData ?
                    <Sidebar {...props}></Sidebar>
                    :
                    null
            }
            <div className={window.location.href !== `${window.location.origin}/login` && props.uData || props.sidebarShort == true ? 'move-left' : ''}>
                {
                    props[0].location.pathname !== '/login' && props.uData ?
                        <Header {...props} />
                        :
                        null
                }
                <Wrapped {...props} />
            </div>
        </div>
    );
};

export default DefaultLayout;