import Screen from './screen';
import API from './api';
import Login from '../login/screen';
export default {
    path: '/',
    auth: true,
    component: Screen,
    preAuthComponent: Login,
    api: API,
    seo: (data, lang) => {
        return {
            'Articles': 'ICM d.o.o',
            'description': 'Articles...'
        }
    }
}