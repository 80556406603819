import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit.svg';
import garbageIcon from '../../assets/garbage.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';


import { routes } from '../routesList';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';


const SortableItem = SortableElement(({ value, update, lang, className, onSortStart1, dragIndex1, parent }) => <li className={className}> <h6> {value.name}</h6>
    {
        value.submenu && value.submenu.length ?
            <SortableList onSortStart={({ node, index }) => {
                onSortStart1(parent, index)
            }} dragIndex={dragIndex1} hideSortableGhost={false} lang={lang} onSortEnd={({ oldIndex, newIndex }) => update(oldIndex, newIndex)}
                items={value.submenu}></SortableList>
            :
            null
    }

</li>);

const SortableList = SortableContainer(({ items, updateSubMenu, lang, dragIndex, onSortStart1, dragIndex1 }) => {
    return (
        <ul className="sort-list">
            {items.map((value, index) => (
                <SortableItem parent={index} onSortStart1={onSortStart1} dragIndex1={dragIndex1 && dragIndex1.parent === index ? dragIndex1.index : null} className={dragIndex === index ? 'drag' : null} key={`item-${value}`} index={index} value={value} lang={lang} update={(oldIndex, newIndex) => updateSubMenu(index, oldIndex, newIndex)} itemIndex={index} />
            ))}
        </ul>
    );
});

class Screen extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            routes: [],
            sortModules: [],
            modules: [],
            items: [

            ]
        }
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ sortModules }) => ({
            sortModules: arrayMove(sortModules, oldIndex, newIndex),
            dragIndex: null
        }));
    };
    updateSubMenu = (index, oldIndex, newIndex) => {
        console.log(index, oldIndex, newIndex)
        let sortModules = this.state.sortModules;
        let newItems = this.state.sortModules[index].submenu;
        newItems = arrayMove(newItems, oldIndex, newIndex);
        sortModules[index].submenu = newItems;
        this.setState({
            sortModules,
            dragIndex1: null
        })

    };
    updatePosition = () => {
        let positions = [];
        let sortModules = this.state.sortModules;
        for (let i = 0; i < sortModules.length; i++) {
            positions.push({
                _id: sortModules[i]._id,
                position: i
            });
            if (sortModules[i].submenu && sortModules[i].submenu.length) {
                for (let j = 0; j < sortModules[i].submenu.length; j++) {
                    positions.push({
                        _id: sortModules[i].submenu[j]._id,
                        position: j
                    })
                }
            }
        }
        this._api('save-sort-modules', { positions }, () => {
            if (typeof window != 'undefined') {
                window.location.reload()
            }
        });
    }


    render() {
        let params = this._getParams();

        return (
            <div>
                <div className="section-title title-options">
                    <h1>Modules</h1>
                    <div className="title-options-group">
                        <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Save</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Print</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Delete module <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row>
                        <Col lg="6">

                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                classNameForm={"add-form-container"}
                                // headerForm={"Artikal".translate(this.props.lang)}
                                addButtonText={'Add new'.translate(this.props.lang)}
                                onSubmit={(dat) => {
                                    let data = { ...dat }

                                    data.endpoints = [];
                                    if (data.endpoint) {
                                        for (var key in data.endpoint) {
                                            if (data.endpoint[key]) {
                                                data.endpoints.push(key);
                                            }
                                        }
                                        delete data.endpoint;
                                    }


                                    if (this.state.form && this.state.form._id) {

                                        this._api('api4', { data: data }, () => {
                                            this.setState({ form: undefined }, () => { this._onLoad(); })
                                        });
                                    } else {

                                        this._api('api2', { data: data }, () => {
                                            this.setState({ clientForm: {} }, () => { this._onLoad() })
                                        });
                                    }
                                }}
                                initialValues={this.state.form ? this.state.form : null}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'block',
                                                        header: this.state.form && this.state.form._id ? 'Edit module'.translate(this.props.lang) : 'Add module'.translate(this.props.lang),
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 6, xs: 6 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'name',
                                                                        next: 'icon',
                                                                        label: 'Name',
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Required!'),
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 3, sm: 6, xs: 6 },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        name: 'type',
                                                                        label: 'Type',
                                                                        values: [
                                                                            { value: null, name: 'Choose' },
                                                                            { value: 'menu', name: 'Menu' },
                                                                            { value: 'submenu', name: 'Submenu' },
                                                                        ],
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            async (value) => {
                                                                                this.setState({
                                                                                    moduleType: value
                                                                                })

                                                                                return undefined;
                                                                            }
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            this.state.moduleType == 'submenu' ?
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 3, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'select',
                                                                            name: 'parent',
                                                                            label: 'Menu',
                                                                            values: [
                                                                                { value: null, name: 'Choose' },
                                                                                ...this.state.modules.map((item, idx) => {
                                                                                    return {
                                                                                        value: item._id, name: item.name
                                                                                    }
                                                                                })
                                                                            ],
                                                                            validate: []
                                                                        },
                                                                    ]
                                                                } : {
                                                                    type: 'col',
                                                                    width: { lg: 3, sm: 6, xs: 6 },
                                                                    children: [
                                                                    ]
                                                                },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 6, xs: 6 },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        name: 'path',
                                                                        label: 'Link',
                                                                        values: [
                                                                            { value: null, name: 'Choose' },
                                                                            ...routes.map((item) => { return { value: item.path, name: item.path } })
                                                                        ],
                                                                        validate: []
                                                                    },
                                                                ]
                                                            },

                                                            this.state.moduleType == 'menu' ?
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'file',
                                                                            name: 'icon',
                                                                            endpoint: '/data/modules/upload',
                                                                            label: 'CHOOSE ICON',
                                                                            finishField: true,
                                                                            validate: [
                                                                            ]
                                                                        },
                                                                    ]
                                                                }
                                                                :
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 6, xs: 6 },
                                                                    children: [

                                                                    ]
                                                                },

                                                            ...this.state.routes.map((item) => {
                                                                return {

                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'switch',
                                                                            name: 'endpoint.' + item.method + '|||' + item.route,
                                                                            label: <><span className={`api-method-${item.method}`} >{item.method}</span> {item.route}</>,

                                                                        }
                                                                    ]



                                                                }
                                                            })


                                                        ]
                                                    },

                                                ]
                                            }
                                        ]
                                    },


                                ]}
                            ></FormBuilder>
                        </Col>
                        <Col lg="6">
                            <div className="table-modifed ml-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    header={'List'}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'name', label: 'NAME'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'type', label: 'TYPE'.translate(this.props.lang), multilang: false, allowSort: true },

                                    ]}
                                    items={this.state.items}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} className="delete-icon" />,
                                                onClick: (item) => {
                                                    if (item.endpoints) {
                                                        for (let i = 0; i < item.endpoints.length; i++)
                                                            item['endpoint.' + item.endpoints[i]] = true;
                                                    }

                                                    this.setState({ form: null }, () => this.setState({ form: item }))
                                                }
                                                //onClick: (item) => console.log(item)
                                            },
                                            {
                                                component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                            <div className="sort-modules-wrap">
                                <div className="sort-modules-title">
                                    <h4>Sortiranje modula</h4>
                                    <button onClick={this.updatePosition}>
                                        <div className="option green">
                                            <Isvg src={saveIcon} />
                                        </div>
                                        <span>Snimi</span>
                                    </button>
                                </div>

                                <SortableList onSortStart={({ node, index }) => {
                                    this.setState({ dragIndex: index })
                                }}

                                    dragIndex={this.state.dragIndex}
                                    onSortStart1={(parent, index) => {
                                        console.log(parent, index)
                                        this.setState({ dragIndex1: { parent: parent, index: index } })
                                    }}

                                    dragIndex1={this.state.dragIndex1}
                                    hideSortableGhost={false} lang={this.props.lang} items={this.state.sortModules} onSortEnd={this.onSortEnd} updateSubMenu={this.updateSubMenu} />

                            </div>

                        </Col>
                    </Row>
                </Container>

            </div >
        )
    }
}

export default Page(Screen);
