import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'api1': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/modules', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },
    'api2': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/modules/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'api3': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/modules/' + data.data, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                //body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'api4': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/modules/' + data.data._id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },
    'endpoints': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/modules/endpoints', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { routes: result };
            })
        }
    },
    'modules': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/modules', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: 0,
                    entries: null,
                    filter: null,
                    query: { parent: null },
                    sortType: 1,
                    sortField: 'name',

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        modules: result,
                    }
            })
        }
    },
    'all-modules': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/modules', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: 0,
                    entries: null,
                    filter: null,
                    sortType: 1,
                    sortField: 'position',

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    let tree = [];
                    for (let i = 0; i < result.length; i++) {
                        if (!result[i].parent) {
                            let obj = result[i];
                            obj.submenu = [];
                            for (let j = 0; j < result.length; j++) {
                                if (result[j].parent && result[j].parent == result[i]._id) {
                                    obj.submenu.push(result[j]);
                                }

                            }
                            tree.push(obj);
                        }
                    }
                    return {
                        sortModules: tree,
                    }
                }

            })
        }
    },
    'save-sort-modules': {
        onLoad: false,
        action: (positions) => {
            return fetch(env.API_URL + '/modules/sort', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({ modules: positions && positions.positions })
            }).then(parseJSON).then(({ result, status }) => {
                console.log(result)

            })
        }
    },




}