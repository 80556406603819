import LoginPage from './login';
import ArticlesPage from './articles';
import UsersPage from './users';
import UserLevelsPage from './userLevels';
import ModulesPage from './modules';
import CategoriesPage from './tables';
import ConditionsPage from './conditions';
import ReservationsPage from './reservations';

export const routes = [
    LoginPage,
    ArticlesPage,
    UsersPage,
    UserLevelsPage,
    ModulesPage,
    CategoriesPage,
    ConditionsPage,
    ReservationsPage
]

