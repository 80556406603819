import Screen from './screen';
import API from './api';
import Login from '../login/screen';

export default {
    path: '/modules',
    auth: true,
    component: Screen,
    preAuthComponent: Login,
    api: API,
    seo: (data, lang) => {
        return {
            'Expense': 'ICM d.o.o',
            'description': 'Expense...'
        }
    }
}