
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    FormGroup, Label
} from 'reactstrap';

import { Editor } from '@tinymce/tinymce-react';
/**
* Component for html field
* @author   Milan Stanojevic
*/
class HtmlImage extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <FormGroup>
                {this.props.label ? <Label>{this.props.label}</Label> : null}

                <Editor
                    disabled={this.props.disabled}
                    apiKey="lweh8ghdiwccyg0ogbblrkzamnybbrxoay2b7ontj3tusq7c"
                    init={{ plugins: 'link table code print image', height: this.props.height ? this.props.height : 500, content_css: '/content.css' }}
                    init={{
                        plugins: 'link table code print image', height: this.props.height ? this.props.height : 500, content_css: '/content.css'}}
                    value={this.props.multilang ? (this.props.value && this.props.value[this.props.lang]) ? this.props.value[this.props.lang] : '' : this.props.value}
                    onEditorChange={(val) => {

                        if (this.props.multilang) {
                            let value = this.props.value;
                            if (!value) {
                                value = {};
                            }
                            value[this.props.lang] = val;

                            this.props.onChange(value);
                        } else {

                            this.props.onChange(val);
                        }
                        this.forceUpdate();


                    }} />

            </FormGroup>




        );
    }
}

export default HtmlImage;