import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

const API_URL = env ? env.API_URL : null;

export default {
    'api1': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(API_URL + '/data/modules', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        total: result.total,
                    }
            })
        }
    },
    'conditions': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(API_URL + '/data/conditions', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: null,
                    sortType: null,
                    sortField: null,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        _conditions: result,
                    }
            })
        }
    },

  

}