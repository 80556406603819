import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Row,
    Col,
    Table,
    FormGroup, Label, Input, Button
} from 'reactstrap';
import editIcon from '../../assets/edit.svg';
import garbageIcon from '../../assets/garbage.svg';
import saveIcon from '../../assets/save.svg';
import printIcon from '../../assets/print.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';

import { API_ENDPOINT } from '../../constants';


const tables = [
    (color, number, onPress) => {
        return (
            <div className="table table1" onClick={onPress}>

                <svg xmlns="http://www.w3.org/2000/svg" width="124" height="124.176" viewBox="0 0 124 124.176">
                    <defs>
                        <filter id="Union_3" x="0" y="0" width="124" height="124.176" filterUnits="userSpaceOnUse">
                            <feOffset dy="4" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_3)">
                        <path id="Union_3-2" data-name="Union 3" d="M4,103a4,4,0,0,1-4-4V6.824a4,4,0,0,1,4-4H96a4,4,0,0,1,4,4v32a4,4,0,0,1-4,4H40V99a4,4,0,0,1-4,4Z" transform="translate(12 5.18)" fill={color} />
                    </g>
                </svg>
                <div className="table-number">{number}</div>

            </div>
        )
    },
    (color, number, onPress) => {
        return (
            <div className="table table2" onClick={onPress}>
                <svg width="124.176" height="124" viewBox="0 0 124.176 124">
                    <defs>
                        <filter id="Union_26" x="0" y="0" width="124.176" height="124" filterUnits="userSpaceOnUse">
                            <feOffset dy="4" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_26)">
                        <path id="Union_26-2" data-name="Union 26" d="M4,103a4,4,0,0,1-4-4V6.824a4,4,0,0,1,4-4H96a4,4,0,0,1,4,4v32a4,4,0,0,1-4,4H40V99a4,4,0,0,1-4,4Z" transform="translate(9.18 108) rotate(-90)" fill={color} />
                    </g>
                </svg>
                <div className="table-number">{number}</div>

            </div>


        )
    },
    (color, number, onPress) => {
        return (
            <div className="table table3" onClick={onPress}>
                <svg xmlns="http://www.w3.org/2000/svg" width="124.176" height="124" viewBox="0 0 124.176 124">
                    <defs>
                        <filter id="Union_27" x="0" y="0" width="124.176" height="124" filterUnits="userSpaceOnUse">
                            <feOffset dy="4" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_27)">
                        <path id="Union_27-2" data-name="Union 27" d="M4,103a4,4,0,0,1-4-4V6.824a4,4,0,0,1,4-4H96a4,4,0,0,1,4,4v32a4,4,0,0,1-4,4H40V99a4,4,0,0,1-4,4Z" transform="translate(115 8) rotate(90)" fill={color} />
                    </g>
                </svg>
                <div className="table-number">{number}</div>

            </div>

        )
    },
    (color, number, onPress) => {
        return (
            <div className="table table4" onClick={onPress}>
                <svg xmlns="http://www.w3.org/2000/svg" width="124" height="124.176" viewBox="0 0 124 124.176">
                    <defs>
                        <filter id="Union_28" x="0" y="0" width="124" height="124.176" filterUnits="userSpaceOnUse">
                            <feOffset dy="4" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_28)">
                        <path id="Union_28-2" data-name="Union 28" d="M4,103a4,4,0,0,1-4-4V6.824a4,4,0,0,1,4-4H96a4,4,0,0,1,4,4v32a4,4,0,0,1-4,4H40V99a4,4,0,0,1-4,4Z" transform="translate(112 111) rotate(180)" fill={color} />
                    </g>
                </svg>
                <div className="table-number">{number}</div>

            </div>

        )
    },

    (color, number, onPress) => {
        return (
            <div className="table table5" onClick={onPress}>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="124" viewBox="0 0 64 124">
                    <defs>
                        <filter id="Union_22" x="0" y="0" width="64" height="124" filterUnits="userSpaceOnUse">
                            <feOffset dy="4" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_22)">
                        <path id="Union_22-2" data-name="Union 22" d="M4,0H36a4,4,0,0,1,4,4V96a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z" transform="translate(12 8)" fill={color} />
                    </g>
                </svg>
                <div className="table-number">{number}</div>

            </div>

        )
    },

    (color, number, onPress) => {
        return (
            <div className="table table6" onClick={onPress}>
                <svg xmlns="http://www.w3.org/2000/svg" width="124" height="64" viewBox="0 0 124 64">
                    <defs>
                        <filter id="Union_23" x="0" y="0" width="124" height="64" filterUnits="userSpaceOnUse">
                            <feOffset dy="4" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_23)">
                        <path id="Union_23-2" data-name="Union 23" d="M4,0H96a4,4,0,0,1,4,4V36a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z" transform="translate(12 8)" fill={color} />
                    </g>
                </svg>
                <div className="table-number">{number}</div>

            </div>

        )
    },

    (color, number, onPress) => {
        return (
            <div className="table table7" onClick={onPress}>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                    <defs>
                        <filter id="Union_24" x="0" y="0" width="64" height="64" filterUnits="userSpaceOnUse">
                            <feOffset dy="4" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_24)">
                        <path id="Union_24-2" data-name="Union 24" d="M4,0H36a4,4,0,0,1,4,4V36a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z" transform="translate(52 8) rotate(90)" fill={color} />
                    </g>
                </svg>
                <div className="table-number">{number}</div>

            </div>

        )
    },
    (color, number, onPress) => {
        return (
            <div className="table table8" onClick={onPress}>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                    <defs>
                        <filter id="Union_25" x="0" y="0" width="64" height="64" filterUnits="userSpaceOnUse">
                            <feOffset dy="4" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_25)">
                        <path id="Union_25-2" data-name="Union 25" d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z" transform="translate(52 8) rotate(90)" fill={color} />
                    </g>
                </svg>
                <div className="table-number">{number}</div>

            </div>

        )
    },


]


const tableNames = ['Separe', 'Separe', 'Separe', 'Separe', 'Duzni', 'Duzni', 'Visoki', 'Visoki']


class Home extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            items: [

            ],
            articleTypes: [],
            groups: [],
            subgroups: [],
            manufacturers: [],
            atc: [],
            shapes: [],
            modes: [],
            attributes: [],
            categories: [],
            tables: []
        }
    }



    componentDidMount() {
        this._onLoad();

        this._api('categories', {}, (data) => {
            this.setState({ categories: data.categories })
        })

        this.fetchTables();
    }
    fetchTables = () => {

        fetch(API_ENDPOINT + '/data/tables', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                tables: result
            })
        })

    }


    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();

        console.log(this.state.categories)
        return (
            <div>
                <div className="section-title title-options">
                    <h1>{'Rezervacije'.translate(this.props.lang)}</h1>
                </div>
                {this.state.articleForm ?
                    <div className="table-tabs">
                        <button onClick={() => this.setState({ articleForm: false })}>
                            {'Lista rezervacija'.translate(this.props.lamg)}
                        </button>
                    </div>
                    : null}

                {
                    this.state.articleForm ?

                        <div>
                            <div className="side-margin side-margin-reservation">
                                <div className="tables-wrap">
                                    {
                                        this.state.tables.map((item, idx) => {
                                            if (item.floor == this.state.articleForm.floor)
                                                return (
                                                    <div style={{ position: 'absolute', top: item.position.top, left: item.position.left }}>
                                                        {tables[item.type](this.state.articleForm.tableId == item._id ? '#860000' : '#fff', item.number, () => { })}
                                                    </div>

                                                )
                                        })
                                    }

                                </div>
                                <div className='reservation-info'>
                                    <h6>STO BROJ {this.state.articleForm.tableNumber}</h6>
                                    <div><span>Ime i prezime</span> <strong>{this.state.articleForm.name}</strong> </div>
                                    <div><span>Email</span> <strong>{this.state.articleForm.mail}</strong> </div>
                                    <div><span>Telefon</span> <strong>{this.state.articleForm.phone}</strong> </div>
                                    <div><span>Broj osoba</span> <strong>{this.state.articleForm.personNumber}</strong> </div>
                                    <div><span>Uslov</span> <strong>{this.state.articleForm.reservationType}</strong> </div>
                                    <div><span>Datum</span> <strong>{this.state.articleForm.date} {this.state.articleForm.time}</strong> </div>
                                    <div className='items'>
                                        <p>Hrana</p>
                                        <ul>
                                            {this.state.articleForm.food.map((item, idx) => {
                                                return (
                                                    <li key={idx}>{item.name}</li>
                                                )
                                            })}
                                        </ul>
                                        <p>Pice</p>
                                        <ul>
                                            {this.state.articleForm.drink.map((item, idx) => {
                                                return (
                                                    <li key={idx}>{item.name}</li>
                                                )
                                            })}
                                        </ul>
                                    </div>


                                    <div className='buttons-wrap'>
                                        <Button onClick={() => {
                                            this._api('api4', { data: {_id: this.state.articleForm._id, status: 'allowed'} }, () => {
                                                this.setState({articleForm: null})
                                                this._onLoad();
                                            });

                                        }} color="success" disabled={this.state.articleForm.status == 'allowed'}>ODOBRI</Button>
                                        <Button onClick={() => {
                                            this._api('api4', { data: {_id: this.state.articleForm._id, status: 'disallowed'} }, () => {
                                                this.setState({articleForm: null})
                                                this._onLoad();

                                            });

                                        }}  color="danger" disabled={this.state.articleForm.status == 'disallowed'}>ODBIJ</Button>
                                    </div>
                                </div>

                            </div>

                        </div>

                        :

                        <div>
                            <DeleteModal
                                isOpen={this.state.deleteModal}
                                toggle={() => this.setState({ deleteModal: null })}
                                handler={() => {
                                    this._api('api3', { data: this.state.deleteModal._id }, () => {
                                        this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                                    });
                                }} >
                                {'Izbrisi rezervaciju na ime'.translate(this.props.lang)} <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                            </DeleteModal>
                            <ListBuilder
                                tabs={true}
                                sort={true}
                                tab1Name={'Lista rezervacija'.translate(this.props.lang)}
                                onClickTab1={() => {
                                    this.setState({
                                        articlesList: true,
                                        articleForm: false,
                                    })
                                }}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[

                                    { type: 'text', name: 'name', label: 'Ime i prezime'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'mail', label: 'Email'.translate(this.props.lang), multilang: false, allowSort: true },

                                    { type: 'text', name: 'personNumber', label: 'Broj osoba'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'phone', label: 'Telefon'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'reservationType', label: 'Uslov'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'dateString', label: 'Datum'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'status', label: 'Status'.translate(this.props.lang), multilang: false, allowSort: true },

                                ]}
                                items={this.state.items.map((item) => {
                                    return {
                                        ...item,
                                        dateString: item.date + ' ' + item.time,
                                        status: item.status == 'allowed' ? 'Odobreno' : item.status == 'disallowed' ? 'Odbijeno' : item.status == 'checked' ? 'Potvrdjeno' :  'Na cekanju'
                                    }
                                })}
                                rawItems={this.state.items}
                                actions={
                                    [
                                        {
                                            component: <Isvg src={editIcon} />,
                                            onClick: (item) => this.setState({ articleForm: item })
                                        },
                                        {
                                            component: <Isvg src={garbageIcon} />,
                                            onClick: (item) => this.setState({ deleteModal: item })
                                        },
                                    ]
                                }

                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </div>
                }


            </div>
        )
    }
}

export default Page(Home);

/*

*/