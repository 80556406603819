import { Component } from "react";

import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import Isvg from 'react-inlinesvg';
import arrow from '../../assets/arrow-down.svg';

class Select extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <FormGroup className="position-relative">
                <Label>{this.props.label}</Label>
                <div className="select-field">
                    <select 
                    className={`form-control`}
                    // className={`${this.props.touched && this.props.error ? 'is-invalid' : this.props.touched ? 'is-valid' : ''} form-control`}
                        ref={(node) => this.props.setFieldRef(node)}
                        onChange={(e) => this.props.onChange(e.target.value)}
                        value={this.props.value ? this.props.value:''}
                        placeholder={this.props.placeholder}

                        onKeyUp={(e) => {
                            if (e.key == this.props.finishKey) {
                                this.props.focusNext();
                            }
                        }}

                    >
                        {
                            this.props.values.map((item, idx) => {
                                return (
                                    <option value={item.value}>{item.name}</option>
                                )
                            })
                        }
                    </select>
                    <Isvg src={arrow} />
                </div>
                {this.props.error && this.props.touched ?
                    <FormFeedback invalid tooltip>{this.props.error}</FormFeedback>
                    :

                    null
                }

            </FormGroup>
        )
    }
}

export default Select;
