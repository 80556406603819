import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Label,
    Container,
    Row,
    Col,
    Table,
    FormGroup, Input, Button,
    TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';

import dragIcon from '../../assets/drag.svg';
import { Rnd } from 'react-rnd';
import Text from '../../form-builder/fields/text';
import env from "react-dotenv";

import Switch from '../../form-builder/fields/switch'

import { API_ENDPOINT } from '../../constants';

const tables = [
    (color, number, onPress) => {
        return (
            <div className="table table1" onClick={onPress}>

                <svg xmlns="http://www.w3.org/2000/svg" width="124" height="124.176" viewBox="0 0 124 124.176">
                    <defs>
                        <filter id="Union_3" x="0" y="0" width="124" height="124.176" filterUnits="userSpaceOnUse">
                            <feOffset dy="4" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_3)">
                        <path id="Union_3-2" data-name="Union 3" d="M4,103a4,4,0,0,1-4-4V6.824a4,4,0,0,1,4-4H96a4,4,0,0,1,4,4v32a4,4,0,0,1-4,4H40V99a4,4,0,0,1-4,4Z" transform="translate(12 5.18)" fill={color} />
                    </g>
                </svg>
                <div className="table-number">{number}</div>

            </div>
        )
    },
    (color, number, onPress) => {
        return (
            <div className="table table2" onClick={onPress}>
                <svg width="124.176" height="124" viewBox="0 0 124.176 124">
                    <defs>
                        <filter id="Union_26" x="0" y="0" width="124.176" height="124" filterUnits="userSpaceOnUse">
                            <feOffset dy="4" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_26)">
                        <path id="Union_26-2" data-name="Union 26" d="M4,103a4,4,0,0,1-4-4V6.824a4,4,0,0,1,4-4H96a4,4,0,0,1,4,4v32a4,4,0,0,1-4,4H40V99a4,4,0,0,1-4,4Z" transform="translate(9.18 108) rotate(-90)" fill={color} />
                    </g>
                </svg>
                <div className="table-number">{number}</div>

            </div>


        )
    },
    (color, number, onPress) => {
        return (
            <div className="table table3" onClick={onPress}>
                <svg xmlns="http://www.w3.org/2000/svg" width="124.176" height="124" viewBox="0 0 124.176 124">
                    <defs>
                        <filter id="Union_27" x="0" y="0" width="124.176" height="124" filterUnits="userSpaceOnUse">
                            <feOffset dy="4" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_27)">
                        <path id="Union_27-2" data-name="Union 27" d="M4,103a4,4,0,0,1-4-4V6.824a4,4,0,0,1,4-4H96a4,4,0,0,1,4,4v32a4,4,0,0,1-4,4H40V99a4,4,0,0,1-4,4Z" transform="translate(115 8) rotate(90)" fill={color} />
                    </g>
                </svg>
                <div className="table-number">{number}</div>

            </div>

        )
    },
    (color, number, onPress) => {
        return (
            <div className="table table4" onClick={onPress}>
                <svg xmlns="http://www.w3.org/2000/svg" width="124" height="124.176" viewBox="0 0 124 124.176">
                    <defs>
                        <filter id="Union_28" x="0" y="0" width="124" height="124.176" filterUnits="userSpaceOnUse">
                            <feOffset dy="4" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_28)">
                        <path id="Union_28-2" data-name="Union 28" d="M4,103a4,4,0,0,1-4-4V6.824a4,4,0,0,1,4-4H96a4,4,0,0,1,4,4v32a4,4,0,0,1-4,4H40V99a4,4,0,0,1-4,4Z" transform="translate(112 111) rotate(180)" fill={color} />
                    </g>
                </svg>
                <div className="table-number">{number}</div>

            </div>

        )
    },

    (color, number, onPress) => {
        return (
            <div className="table table5" onClick={onPress}>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="124" viewBox="0 0 64 124">
                    <defs>
                        <filter id="Union_22" x="0" y="0" width="64" height="124" filterUnits="userSpaceOnUse">
                            <feOffset dy="4" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_22)">
                        <path id="Union_22-2" data-name="Union 22" d="M4,0H36a4,4,0,0,1,4,4V96a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z" transform="translate(12 8)" fill={color} />
                    </g>
                </svg>
                <div className="table-number">{number}</div>

            </div>

        )
    },

    (color, number, onPress) => {
        return (
            <div className="table table6" onClick={onPress}>
                <svg xmlns="http://www.w3.org/2000/svg" width="124" height="64" viewBox="0 0 124 64">
                    <defs>
                        <filter id="Union_23" x="0" y="0" width="124" height="64" filterUnits="userSpaceOnUse">
                            <feOffset dy="4" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_23)">
                        <path id="Union_23-2" data-name="Union 23" d="M4,0H96a4,4,0,0,1,4,4V36a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z" transform="translate(12 8)" fill={color} />
                    </g>
                </svg>
                <div className="table-number">{number}</div>

            </div>

        )
    },

    (color, number, onPress) => {
        return (
            <div className="table table7" onClick={onPress}>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                    <defs>
                        <filter id="Union_24" x="0" y="0" width="64" height="64" filterUnits="userSpaceOnUse">
                            <feOffset dy="4" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_24)">
                        <path id="Union_24-2" data-name="Union 24" d="M4,0H36a4,4,0,0,1,4,4V36a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z" transform="translate(52 8) rotate(90)" fill={color} />
                    </g>
                </svg>
                <div className="table-number">{number}</div>

            </div>

        )
    },
    (color, number, onPress) => {
        return (
            <div className="table table8" onClick={onPress}>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                    <defs>
                        <filter id="Union_25" x="0" y="0" width="64" height="64" filterUnits="userSpaceOnUse">
                            <feOffset dy="4" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="4" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_25)">
                        <path id="Union_25-2" data-name="Union 25" d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z" transform="translate(52 8) rotate(90)" fill={color} />
                    </g>
                </svg>
                <div className="table-number">{number}</div>

            </div>

        )
    },


]


const tableNames = ['Separe', 'Separe', 'Separe', 'Separe', 'Duzni', 'Duzni', 'Visoki', 'Visoki']

class Home extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            _tableNumber: 1,
            _selected: 0,
            floor: 0,
            _minNumberOfPeople: 1,
            _conditions: [],
            conditions: [],
            items: [

            ]
        }
    }

    componentDidMount() {
        this._onLoad();
        this.fetchTables();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    fetchTables = () => {

        fetch(API_ENDPOINT + '/data/tables', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                items: result
            })
        })

    }

    addTable = () => {
        if (this.state._selectedTable) {
            fetch(API_ENDPOINT + '/data/tables/' + this.state._selectedTable._id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    position: this.state._selectedTable ? this.state._selectedTable.position :
                        {
                            top: 0,
                            left: 0
                        },
                    floor: this.state.floor,
                    minNumberOfPeople: this.state._minNumberOfPeople,
                    conditions: this.state.conditions,
                    type: this.state._selected,
                    number: this.state._tableNumber
                })
            }).then((res) => res.json()).then((result) => {
                console.log(result);
                this.setState({
                    _selectedTable: null,
                    _tableNumber: 1,
                    _selected: 0,
                    _minNumberOfPeople: 1,
                    conditions: [],

                })
                this.fetchTables();
            })

        } else {
            fetch(API_ENDPOINT + '/data/tables/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    position: this.state._selectedTable ? this.state._selectedTable.position :
                        {
                            top: 0,
                            left: 0
                        },
                    floor: this.state.floor,
                    minNumberOfPeople: this.state._minNumberOfPeople,
                    conditions: this.state.conditions,
                    type: this.state._selected,
                    number: this.state._tableNumber
                })
            }).then((res) => res.json()).then((result) => {
                console.log(result);
                this.setState({
                    _selectedTable: null,
                    _tableNumber: 1,
                    _selected: 0,
                    _minNumberOfPeople: 1,
                    conditions: [],

                })
                this.fetchTables();
            })

        }


    }

    saveTableOrder = () => {
        for (let i = 0; i < this.state.items.length; i++) {
            fetch(API_ENDPOINT + '/data/tables/' + this.state.items[i]._id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    position: this.state.items[i].position
                })
            }).then((res) => res.json()).then((result) => {
                console.log(result);
            })
        }

        this.setState({
            _selectedTable: null,
            _tableNumber: 1,
            _selected: 0,
            _minNumberOfPeople: 1,
            conditions: [],
})

    }



    render() {
        let params = this._getParams();


        return (
            <div className="tables-area">
                <div className="section-title title-options">
                    <h1>{'Raspored'.translate(this.props.lang)}</h1>
                </div>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={this.state.floor === 0 ? 'active' : ''} onClick={() => this.setState({ floor: 0 })}>
                            Prizemlje
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={this.state.floor === 1 ? 'active' : ''} onClick={() => this.setState({ floor: 1 })}>
                            Sprat
                        </NavLink>
                    </NavItem>
                </Nav>
                <div className="tables-list">
                    {this.state._selectedTable ?
                        <h3>Sto broj {this.state._selectedTable.number}</h3>

                        :
                        <h3>Dodaj novi sto</h3>
                    }
                    <h6>Tipovi stolova</h6>
                    <ul className="tables-ul">
                        {
                            tables.map((item, idx) => {
                                return (
                                    <li onClick={() => this.setState({ _selected: idx })}>
                                        {item(this.state._selected == idx ? '#860000' : '#fff', '', () => { this.setState({ _selected: idx }) })}
                                        <span>{tableNames[idx]}</span>
                                    </li>
                                )
                            })
                        }
                    </ul>

                    <div className="form">
                        <FormGroup>
                            <Label>Broj stola</Label>
                            <Input type="text" placeholder="Unseite broj stola" value={this.state._tableNumber} onChange={(e) => { console.log(e); this.setState({ _tableNumber: e.target.value }) }} />
                        </FormGroup>

                        <FormGroup>
                            <Label>Minimalan broj osoba za stolom</Label>
                            <Input type="text" placeholder="Unseite broj osoba" value={this.state._minNumberOfPeople} onChange={(e) => { console.log(e); this.setState({ _minNumberOfPeople: e.target.value }) }} />
                        </FormGroup>

                        <div className="listbox">
                            <h6>Uslovi</h6>
                            <ul>
                                {
                                    this.state._conditions.map((item, idx) => {
                                        return (
                                            <li >
                                                <Switch label={item.name} onChange={() => {
                                                    let conditions = this.state.conditions;
                                                    conditions = item._id;
                                                    this.setState({
                                                        conditions
                                                    })

                                                }} value={this.state.conditions == item._id} />
                                            </li>

                                        )
                                    })
                                }
                            </ul>
                        </div>
                        {
                            this.state._selectedTable ?
                                <Button color="primary" onClick={this.addTable}>IZMJENI STO</Button>

                                :
                                <Button color="primary" onClick={this.addTable}>DODAJ STO</Button>
                        }
                    </div>
                </div>



                <div className="tables-wrap">
                    {
                        this.state.items.map((item, idx) => {
                            if (item.floor == this.state.floor)
                                return (
                                    <Rnd
                                        enableResizing={false}
                                        position={{ x: item.position.left, y: item.position.top }}
                                        onClick={() => {
                                            this.setState({
                                                conditions: item.conditions,
                                                _minNumberOfPeople: item.minNumberOfPeople,
                                                _selected: item.type,
                                                _tableNumber: item.number,
                                                _selectedTable: item
                                            })
                                        }}
                                        onDragStop={(e, d) => {
                                            let items = this.state.items;

                                            if (item.position.top == d.y && item.position.left == d.x) {
                                                return;
                                            }

                                            items[idx].position.top = d.y;
                                            items[idx].position.left = d.x;

                                            this.setState({ items, _tablesChanged: true })
                                        }}
                                    >
                                        {tables[item.type](this.state._selectedTable && this.state._selectedTable._id == item._id ? '#860000' : '#fff', item.number, () => { })}
                                    </Rnd>

                                )
                        })
                    }

                </div>
                {this.state._tablesChanged ?
                    <Row style={{ marginTop: 20 }}>
                        <Col lg="12">
                            <Button color="primary" onClick={this.saveTableOrder}>Sačuvaj raspored</Button>
                        </Col>
                    </Row>


                    : null
                }

            </div>
        )
    }
}

export default Page(Home);

/*

*/