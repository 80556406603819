import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import logo from '../assets/kafana.png';
import menuIcon from '../assets/hamburger.svg';
import micIcon from '../assets/mic.svg';
import testIcon from '../assets/logout.svg';
import env from "react-dotenv";
import { API_ENDPOINT } from '../constants';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownMenu: false,
            subDropdownMenu: false,
            rotateSubArrow: false
        }
    }

    render() {

        return (
            <div className={this.props.sidebarShort ? 'sidebar sidebar-short' : 'sidebar'}>
                <div className="sidebar-scroll-area">
                    <div className="sidebar-header">
                        <img src={logo} className="logo" />
                        <div className="menu-icon-box">
                            <Isvg src={menuIcon} className="menu-icon" />
                        </div>
                    </div>
                    <div className="sidebar-body">
                        <ul className="main-menu">
                            {
                                this.props.modulesTree.map((item, idx) => {

                                    let subpaths = [];
                                    if (item.modules && item.modules.length == 0)
                                        return (
                                            <li>
                                                <div className={this.props[0].location.pathname == item.path ? "main-menu-block active" : "main-menu-block"}>
                                                    <Link to={item.path ? item.path : null}>
                                                        <div className="sidebar-icon">
                                                            <Isvg src={API_ENDPOINT + item.icon} />
                                                        </div>
                                                        <span className="link-text">{item.name}</span>
                                                    </Link>
                                                </div>
                                            </li>

                                        )
                                    else
                                        return (
                                            <li>
                                                <div className={this.state.dropdownMenu ? 'main-menu-block rotate' : 'main-menu-block'} onClick={() => {
                                                    this.setState({
                                                        dropdownMenu: !this.state.dropdownMenu
                                                    })
                                                }}>
                                                    <Link to={item.path ? item.path : null}>
                                                        <div className="sidebar-icon">
                                                            <Isvg src={API_ENDPOINT + item.icon} />
                                                        </div>
                                                        <span className="link-text">{item.name}</span>
                                                    </Link>
                                                </div>
                                                <ul className={this.state.dropdownMenu ? 'main-menu-dropdown open' : 'main-menu-dropdown'}>
                                                    {
                                                        item.modules && item.modules.map((submodule, sidx) => {
                                                            return (
                                                                <li>
                                                                    <Link to={submodule.path ? submodule.path : item.path ? item.path : null}>{submodule.name}</Link>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>



                                            </li>
                                        )
                                })
                            }
                            <li style={{ marginTop: 50 }}>
                                <div className="main-menu-block main-menu-block-without-arrow" onClick={() => this.props.signOut(() => this.props[0].history.push('/login'))}>
                                    <a >
                                        <div className="sidebar-icon">

                                            <Isvg src={testIcon} />
                                        </div>
                                        <span className="link-text">{'Logout'.translate(this.props.lang)}</span>
                                    </a>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
                {/* <div className="sidebar-footer">
                    <div className="fast-help">
                        <Isvg src={micIcon} />
                        <h6>Potrebna Vam je pomoć?</h6>
                        <p>Slobodno nas kontaktirate</p>
                        <button>POKRENI POZIV</button>
                    </div>
                </div> */}
            </div>
        )
    }
}

export default Sidebar;
