import { Component } from "react";

import { FormGroup, Input, Label, FormFeedback, Row, Col } from 'reactstrap';

import Isvg from 'react-inlinesvg';
import x from '../../assets/x.svg';
import plus from '../../assets/plus.svg';


class Categories extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    getNodeChildren = (id) => {
        let items = [];
        let categories = JSON.parse(JSON.stringify(this.props.categories));

        for (let i = 0; i < categories.length; i++) {
            if (categories[i].parent == id) {
                items.push(categories[i]);
            }
        }

        return items;

    }

    getNodeSiblings = (parent) => {
        let items = [];
        let categories = JSON.parse(JSON.stringify(this.props.categories));

        for (let i = 0; i < categories.length; i++) {
            if (categories[i].parent == parent) {
                items.push(categories[i]);
            }
        }

        return items;

    }

    getTree = (node) => {
        let categories = JSON.parse(JSON.stringify(this.props.categories));

        let nodes = [node];
        console.log(nodes)
        if (node.parent) {
            for (let i = 0; i < categories.length; i++) {
                if (node.parent == categories[i]._id) {

                    nodes = [...nodes, ...this.getTree(categories[i])];
                    break;
                }
            }
        }




        return nodes;

    }

    render() {
        let items = [];
        if (this.props.value) {
            for (let i = 0; i < this.props.value.length; i++) {

                for (let j = 0; j < this.props.categories.length; j++) {
                    if (this.props.categories[j]._id == this.props.value[i].id) {
                        items.push(JSON.parse(JSON.stringify(this.getTree(this.props.categories[j]))))
                        break;
                    }
                }

            }
        }



        for (let i = 0; i < items.length; i++) {
            for (let j = 0; j < items[i].length; j++) {
                items[i][j].siblings = this.getNodeSiblings(items[i][j].parent);
                items[i][j].children = this.getNodeChildren(items[i][j]._id);
            }

            items[i].reverse();
        }

        console.log(items);

        return (
            <FormGroup className="position-relative">
                <Label>{this.props.label}</Label>
                {
                    items.map((category, cidx) => {
                        return (
                            <div className="category-wrap">
                                {
                                    category.map((item, idx) => {
                                        return (
                                            <div className="category">
                                                <Input type="select" value={item._id} onChange={(e) => {
                                                    let value = this.props.value ? this.props.value : [];
                                                    value[cidx] = {
                                                        //...value[value.length -1 - cidx],
                                                        id: e.target.value
                                                    }
                                                    this.props.onChange(value)
                                                }}>
                                                    {
                                                        item.siblings.map((option) => {
                                                            return <option value={option._id}>{option.name['en']}</option>
                                                        })
                                                    }
                                                </Input>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    category[category.length - 1].children && category[category.length - 1].children.length ?
                                        <div className="category">
                                            <Input type="select" onChange={(e) => {
                                                let value = this.props.value ? this.props.value : [];
                                                value[cidx] = {
                                                    //...value[value.length -1 - cidx],
                                                    id: e.target.value
                                                }
                                                this.props.onChange(value)
                                            }}>
                                                <option value={null}>{'Choose'}</option>
                                                {
                                                    category[category.length - 1].children.map((option) => {
                                                        return <option value={option._id}>{option.name['en']}</option>
                                                    })
                                                }
                                            </Input>
                                        </div>

                                        :
                                        null
                                }
                                <button onClick={() => {
                                    let value = this.props.value;
                                    value.splice(cidx, 1);
                                    this.props.onChange(value);
                                }}>
                                    <Isvg src={x} />
                                </button>
                            </div>
                        )
                    })
                }
                <div className="category-wrap">
                    <div className="category">
                        <Input value={-1} type="select" onChange={(e) => {
                            let value = this.props.value ? this.props.value : [];
                            value[value.length] = {
                                id: e.target.value
                            }
                            this.props.onChange(value)
                        }}>
                            <option value={-1}>{'Choose'}</option>
                            {
                                this.props.categories.filter(item => !item.parent).map((option) => {
                                    return <option value={option._id}>{option.name['en']}</option>
                                })
                            }
                        </Input>
                    </div>

                </div>


            </FormGroup>
        )
    }
}

export default Categories;
