
import React, { Component } from 'react';
import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';

import TagsInput from 'react-tagsinput'

import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.
/**
* Tags component
* @author   Milan Stanojevic
*/
class Tags extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }






    render() {

        return (
            <FormGroup>
                {this.props.label ? <Label size="sm">{this.props.label}</Label> : null}
                <TagsInput value={this.props.multilang ? (this.props.value && this.props.value[this.props.lang]) ? this.props.value[this.props.lang] : [] : this.props.value ? this.props.value : []} onChange={(val) => {
                    if (this.props.multilang) {
                        let value = this.props.value;
                        if (!value) {
                            value = {};
                        }
                        value[this.props.lang] = val;


                        this.props.onChange(value);
                    } else {
                        this.props.onChange(val);
                    }
                    //this.forceUpdate();

                }} />

                {this.props.error ? <FormFeedback >{this.props.error}</FormFeedback> : null}


            </FormGroup>
        );
    }
}

export default Tags;